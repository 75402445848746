import React from 'react';
import {
  required,
  Create,
  Datagrid,
  DateField,
  Edit,
  FileField,
  FileInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput
} from 'react-admin';

import List from './List';
import { S3FileField } from './fields';

export const TemplateFileList = props => (
  <List {...props} sort={{ field: 'updated_at', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="name" fullWidth />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </Datagrid>
  </List>
);

export const TemplateFileCreate = props => (
  <Create redirect="list" {...props}>
    <SimpleForm width={'50%'}>
      <TextInput source="name" validate={[required()]} fullWidth />
      <FileInput source="file" label="Template File" validate={[required()]}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export const TemplateFileShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="name" fullWidth />
        <S3FileField source="file" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const TemplateFileEdit = props => (
  <Edit redirect="show" {...props}>
    <SimpleForm width={'50%'}>
      <TextInput source="name" validate={[required()]} fullWidth />
      <FileInput source="file" label="Template File" validate={[required()]}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);

